import React from 'react'
import styled from 'styled-components'
import { Checkbox, Button } from '@aristech/components'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import Cookie, { State } from './Cookie'
import { useIntl } from 'gatsby-plugin-intl'
import Link from '../Link'
// import { CSSTransition } from 'react-transition-group'
// import { useAnimate } from 'react-simple-animate'
import { PageContext } from '../PageContext'
import ErrorBoundary from '../ErrorBoundary'

const OuterWrapper = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  -js-display: flex;
  display: flex;
  justify-content: center;
  z-index: 100000;
  user-select: none;

  // transition: transform 0.25s ease-out;
  /*
  &.state--exit {
    transition: transform 0.25s ease-out;
    transform: translateY(200px);
  }
  &.state--exit-done {
    transform: translateY(200px);
    display: none;
  }
  */
`
const Wrapper = styled.div`
  max-width: 350px;
  background: white;
  border-bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07); */
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 400px) {
    max-width: initial;
    left: 0;
    margin: 0 1.5rem;
  }
`
const InnerWrapper = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`
const Settings = styled.div`
  -js-display: flex;
  display: flex;
  .cookie {
    width: 50px;
    flex-shrink: 0;
    margin-right: 1rem;
    height: 100px;
  }
  a,
  a:link {
    display: inline-block;
    color: ${props => props.theme.secondary};
  }
`
const CheckboxWrapper = styled.div`
  padding: 0.5em 0;
`
const Header = styled.h1`
  font-size: ${props => props.theme.scale ** 1.5}rem !important;
  margin: 0;
  text-align: center;
`
const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    transform-origin: center center;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background: #333;
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`
const Footer = styled.div`
  -js-display: flex;
  display: flex;
  height: 30px;
  align-items: center;
  > * + * {
    margin-left: 0.5em;
  }
`

const CookieConsent: React.FC = () => {
  // Consent only determines if the user clicked on save (not what he chose)
  const [consent, saveConsent] = useLocalStorage<boolean | null>(
    'cookie-consent-saved',
    null
  )
  // When the user clicks on save depending on this value
  // we opt-out of third-party cookies for tracking like google analytics
  const [allow, setAllow] = React.useState<boolean | undefined>()

  const setConsent = React.useCallback(
    (allow?: boolean) => {
      if (!allow) {
        const { gaOptout } = window as any
        if (gaOptout === 'function') {
          gaOptout()
          // console.log('ga opted out...')
          console.log('To much sugar is bad anyway 😉')
        } else {
          console.warn('Could not find gaOptout function...')
        }
      } else {
        console.log('Thanks for allowing cookies 🍪 😍...')
      }
      saveConsent(true)
    },
    [saveConsent]
  )

  // These values are persistant across page transitions (@see gatsby-browser.js and components/PageContext)
  const { hideCookieConsent, setHideCookieConsent } = React.useContext(
    PageContext
  )
  const intl = useIntl()
  let show = true
  if (consent || hideCookieConsent) {
    show = false
  }
  // const show = !consent && !hideCookieConsent
  // console.log({ show, consent, hideCookieConsent })
  // const { style } = useAnimate({
  //   start: { transform: 'translateY(0px)' },
  //   end: { transform: 'translateY(200px)' },
  //   complete: { display: 'none' },
  //   easeType: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  //   play: !show,
  //   duration: 0.25,
  // })

  let state: State = 'none'
  if (typeof allow === 'boolean') {
    state = allow ? 'allow' : 'forbid'
  }

  // <CSSTransition in={show} timeout={500} classNames="state-" unmountOnExit>
  return (
    <OuterWrapper style={!show ? { display: 'none' } : {}}>
      <Wrapper>
        <InnerWrapper>
          <CloseButton onClick={() => setHideCookieConsent(true)} />
          <Header>
            {intl.formatMessage({
              id: 'cookie_consent.header',
            })}
          </Header>
          <Settings>
            <Cookie state={state} />
            <div>
              <CheckboxWrapper>
                <Checkbox
                  checked
                  disabled
                  label={intl.formatMessage({
                    id: 'cookie_consent.functional_cookies_label',
                  })}
                />
                <Checkbox
                  checked={!!allow}
                  onChange={() => {
                    setAllow(!allow)
                  }}
                  label={intl.formatMessage({
                    id: 'cookie_consent.stat_cookies_label',
                  })}
                />
              </CheckboxWrapper>
              <Footer>
                <Button
                  color={allow ? 'primary' : 'none'}
                  size="small"
                  onClick={() => setConsent(allow)}
                >
                  {intl.formatMessage({ id: 'cookie_consent.save' })}
                </Button>
                <Link to={'/privacy-policy'}>
                  {intl.formatMessage({ id: 'cookie_consent.privacypolicy' })}
                </Link>
              </Footer>
            </div>
          </Settings>
        </InnerWrapper>
      </Wrapper>
    </OuterWrapper>
  )
  // </CSSTransition>
}

const SaveCookieConsent: React.FC = () => {
  return (
    <ErrorBoundary>
      <CookieConsent />
    </ErrorBoundary>
  )
}

export default SaveCookieConsent
